<template>
  <v-navigation-drawer
    app
    color="#212121"
    persistent    
    permanent
    mini-variant
    enable-resize-watcher
    width="180"
  >
    
    <v-list dense>
      <v-list-item class="px-2">
        <a href="/">
          <v-list-item-avatar>          
              <img alt="meteron-logo" src="/logo.png"/>         
          </v-list-item-avatar>
        </a>
      </v-list-item>


      <template v-for="(item, i) in navigationMenu">
        <!-- main heading -->
        <v-row v-if="item.heading && i === 0 && !mini" align-center :key="i">
          <v-col>
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
        </v-row>

        <v-row v-else-if="item.heading && !mini" align-center :key="i">
          <v-col>
            <v-subheader v-if="item.heading">
              {{ item.heading }}
            </v-subheader>
          </v-col>
        </v-row>
        <v-divider v-else-if="item.divider" :key="i"></v-divider>

        <v-list-group
          v-else-if="item.children"
          v-model="item.model"
          no-action
          :key="i"
          class="v-list-group--default"
        >
          <template v-slot:activator>
            <v-list-item-action
              style="
                iconify {
                  color: red;
                }
              "
            >
              <v-icon class="secondary--text">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <span
            v-for="(child, i) in item.children"
            :key="i"
            link
            :to="!child.target ? child.href : null"
            :href="child.target ? child.href : null"
            ripple
            :router="!child.target"
            :target="child.target"
          >
            <v-list-group :value="true" no-action sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="child.text"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item
                v-for="(child, i) in child.children"
                :key="i"
                link
                :to="!child.target ? child.href : null"
                :href="child.target ? child.href : null"
                ripple
                :router="!child.target"
                :target="child.target"
              >
                <v-list-item-title v-text="child.text"></v-list-item-title>
              </v-list-item>
            </v-list-group>
          </span>
        </v-list-group>
        <span v-else :key="i">
          <v-list-item
            :to="!item.target ? item.href : null"
            :href="item.target ? item.href : null"
            ripple
            v-bind:router="!item.target"
            v-bind:target="item.target"
            v-bind:disabled="item.disabled"
          >
            <v-list-item-action v-if="item.icon">
              <v-icon class="secondary--text">{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </span>
      </template>
    </v-list>

  
  </v-navigation-drawer>
</template>

<style lang="sass">
.v-list-group.v-list-group--default
  .v-list-group__header
    min-height: 32px
    > .v-list-item__icon
      margin-bottom: 6px
      margin-top: 6px
</style>


<script>
export default {
  data: () => ({
    mini: true,
    isActive: false
  }),

  mounted() {
    this.isActive = this.$store.state.user.drawer.isActive
  },

  watch: {
    '$store.state.user.drawer.isActive'(isActive) {
      this.isActive = isActive
    },
    'isActive'(isActive) {
      this.$store.commit('VUETIFY_DRAWER', isActive)
    }
  },

  computed: {
    
    navigationMenu() {      

      let userMenu = [        
        { icon: 'mdi-home-outline', text: 'Home', href: '/' },
        { divider: true },  
        { icon: 'mdi-table', text: 'Queue', href: `/queue` },       
      ]


      return userMenu
    }
  }
}
</script>
